"use strict";

define(["flight/lib/component", "$app/ui/home/with_confetti"], function(
  defineComponent,
  WithConfetti
) {
  return defineComponent(Index, WithConfetti);

  function Index() {
    this.defaultAttrs({
      featureTabSelector: ".js-feature-tab",
      screenshotSelector: ".js-feature-screenshot",
      iphoneSelector: ".js-iphone"
    });

    this.switchToFeature = function(ev, ui) {
      ev && ev.preventDefault();

      if (ev.originalEvent !== undefined) {
        clearInterval(this.attr.slideshow);
      } else {
        ev.stopPropagation();
      }

      this.select("featureTabSelector").removeClass("selected");

      var $tab = $(ui.el);

      $tab.addClass("selected");
      this.select("screenshotSelector")
        .removeClass(this.attr.features.join(" "))
        .addClass($tab.data("feature"));
      this.select("iphoneSelector").toggleClass(
        "showing",
        !!$tab.data("iphone")
      );
    };

    this.after("initialize", function() {
      this.attr.features = [];
      this.attr.currentFeature = 0;

      var that = this;
      this.select("featureTabSelector").each(function() {
        that.attr.features.push($(this).data("feature"));
      });

      this.attr.slideshow = setInterval(
        function() {
          if (this.attr.currentFeature === this.attr.features.length) {
            this.attr.currentFeature = 0;
          }

          $(
            this.select("featureTabSelector")[this.attr.currentFeature]
          ).click();
          this.attr.currentFeature++;
        }.bind(this),
        3000
      );

      this.on("click", {
        featureTabSelector: this.switchToFeature
      });
    });
  }
});
