"use strict";

define(["$app/utils/base_page", "$app/ui/home/index"], function(
  BasePage,
  IndexUI
) {
  function initialize() {
    BasePage.initialize();
    IndexUI.attachTo("body");
  }

  initialize();
});
