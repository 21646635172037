"use strict";

define(["flight/lib/compose"], function() {
  return WithConfetti;

  function WithConfetti() {
    this.defaultAttrs({
      numberOfConfetti: 75
    });

    this.startAnimatingConfetti = function() {
      (function() {
        var COLORS,
          Confetti,
          NUM_CONFETTI,
          PI_2,
          canvas,
          confetti,
          context,
          drawCircle,
          i,
          range,
          drawConfetti,
          resizeWindow,
          xpos = 0.5;

        NUM_CONFETTI = this.attr.numberOfConfetti;

        COLORS = [
          [54, 169, 174],
          [192, 67, 85],
          [253, 206, 113],
          [242, 127, 109]
        ];

        PI_2 = 2 * Math.PI;

        canvas = document.getElementById("confetti");

        context = canvas.getContext("2d");

        window.w = 0;

        window.h = 0;

        resizeWindow = function() {
          window.w = canvas.width = window.innerWidth;
          return (window.h = canvas.height = window.innerHeight);
        };

        window.addEventListener("resize", resizeWindow, false);

        window.onload = function() {
          return setTimeout(resizeWindow, 0);
        };

        range = function(a, b) {
          return (b - a) * Math.random() + a;
        };

        drawConfetti = function(x, y, r, style) {
          context.beginPath();
          context.rect(x, y, r, r);
          context.fillStyle = style;
          return context.fill();
        };

        window.requestAnimationFrame = (function() {
          return (
            window.requestAnimationFrame ||
            window.webkitRequestAnimationFrame ||
            window.mozRequestAnimationFrame ||
            window.oRequestAnimationFrame ||
            window.msRequestAnimationFrame ||
            function(callback) {
              return window.setTimeout(callback, 1000 / 60);
            }
          );
        })();

        Confetti = (function() {
          function Confetti() {
            this.style = COLORS[~~range(0, 4)];
            this.rgb =
              "rgba(" +
              this.style[0] +
              "," +
              this.style[1] +
              "," +
              this.style[2];
            this.r = ~~range(2, 6);
            this.r2 = 2 * this.r;
            this.replace();
          }

          Confetti.prototype.replace = function() {
            this.opacity = 0;
            this.dop = 0.03 * range(1, 4);
            this.x = range(-this.r2, w - this.r2);
            this.y = range(-20, h - this.r2);
            this.xmax = w - this.r;
            this.ymax = h - this.r;
            this.vx = range(0, 2) + 8 * xpos - 5;
            return (this.vy = 0.7 * this.r + range(-1, 1));
          };

          Confetti.prototype.draw = function() {
            var ref;
            this.x += this.vx;
            this.y += this.vy;
            this.opacity += this.dop;
            if (this.opacity > 1) {
              this.opacity = 1;
              this.dop *= -1;
            }
            if (this.opacity < 0 || this.y > this.ymax) {
              this.replace();
            }
            if (!(0 < (ref = this.x) && ref < this.xmax)) {
              this.x = (this.x + this.xmax) % this.xmax;
            }
            return drawConfetti(
              ~~this.x,
              ~~this.y,
              this.r,
              this.rgb + "," + this.opacity + ")"
            );
          };

          return Confetti;
        })();

        confetti = (function() {
          var j, ref, results;
          results = [];
          for (
            i = j = 1, ref = NUM_CONFETTI;
            1 <= ref ? j <= ref : j >= ref;
            i = 1 <= ref ? ++j : --j
          ) {
            results.push(new Confetti());
          }
          return results;
        })();

        window.step = function() {
          var c, j, len, results;
          requestAnimationFrame(step);
          context.clearRect(0, 0, w, h);
          results = [];
          for (j = 0, len = confetti.length; j < len; j++) {
            c = confetti[j];
            results.push(c.draw());
          }
          return results;
        };

        step();
      }.call(this));
    };

    this.after("initialize", function() {
      this.startAnimatingConfetti();
    });
  }
});
